<template>
  <TTView>
    <VRow>
      <VCol>
        <VCard>
          <VCardTitle>Добавить подчинённого</VCardTitle>
          <VCardText>
            <VForm>
              <VTextField
                v-model="from"
                label="id сотрудника"
              />

              <VTextField
                v-model="role"
                label="Роль"
              />
            </VForm>
          </VCardText>

          <VCardActions class="px-4">
            <VBtn
              class="mr-3"
              depressed
              color="primary"
              @click="createLink"
            >
              Добавить
            </VBtn>
            <VBtn
              text
              depressed
              @click="cancel"
            >
              Отмена
            </VBtn>
          </VCardActions>
        </VCard>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
export default {
  name: 'CreateLinkToPersonForm',

  data() {
    return {
      from: '',
      role: 'chief',
    };
  },

  computed: {
    personId() {
      return this.$route.params.personId;
    },

    companyId() {
      return this.$route.params.companyId;
    },
  },

  methods: {
    async createLink() {
      const toPerson = {
        id: this.from,
        role: this.role,
      };
      try {
        await this.$di.api.Orgstructure.peopleCreateLinkToPerson({
          fromPersonId: this.personId,
          toPerson,
          companyId: this.companyId,
        });
        this.$di.notify.snackSuccess('Подчинённый добавлен');
        this.cancel();
      } catch (error) {
        this.$di.notify.errorHandler(error);
      }
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>
